<template>
  <div id="pie"></div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import * as echarts from "echarts/core";
import {
  TooltipComponent,
  LegendComponent,
  TitleComponent,
} from "echarts/components";
import { PieChart } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { apiGetPieData } from "@/service/teacher.js";

echarts.use([
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
  TitleComponent,
]);
const route = useRoute();

watch(
  () => [route.query.class],
  async () => {
    if (route.query.class) {
      await chartData();
    }
  }
);

onMounted(async () => {
  await chartData();
});

const chartData = async () => {
  var chartDom = document.getElementById("pie");
  var myChart = echarts.init(chartDom);

  let res = await apiGetPieData({
    case_id: route.query.case_id ? route.query.case_id : 1,
    gradeId: route.query.grade ? route.query.grade : 1,
    classId: route.query.class ? route.query.class : 1,
  });

  let value = ref([]);

  if (res) {
    if (res.data.length) {
      res.data.forEach((item) => {
        value.value.push({ value: item.tno, name: item.current });
      });
    }
  }

  var option;

  option = {
    title: {
      text: "主观题订正数",
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "",
      x: "right", //可设定图例在左、右、居中 left  center  right
      y: "top", //可设定图例在上、下、居中left  center  right
    },
    series: [
      {
        // name: "Access From",
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: "40",
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: value.value,
      },
    ],
  };

  option && myChart.setOption(option);
};
</script>

<style lang="less" scoped>
#pie {
  padding: 5px;
  box-sizing: border-box;
  height: 200px;
  background-color: rgb(237, 237, 237);
  border-radius: 10px;
}
</style>
