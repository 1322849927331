<template>
  <div id="line"></div>
</template>

<script setup>
import { onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import * as echarts from "echarts/core";
import {
  GridComponent,
  TitleComponent,
  LegendComponent,
} from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { apiLineData } from "@/service/teacher.js";

const route = useRoute();

echarts.use([
  GridComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
  TitleComponent,
  LegendComponent,
]);

watch(
  () => [route.query.class],
  async () => {
    if (route.query.class) {
      await chartData();
    }
  }
);

onMounted(async () => {
  await chartData();
});

const chartData = async () => {
  var chartDom = document.getElementById("line");
  var myChart = echarts.init(chartDom);

  let res = await apiLineData({
    case_id: route.query.case_id ? route.query.case_id : 1,
    gradeId: route.query.grade ? route.query.grade : 1,
    classId: route.query.class ? route.query.class : 1,
  });

  var option;

  option = {
    tooltip: {
      trigger: "axis",
      valueFormatter: (value) => value,
    },
    title: {
      text: "理解数/疑问数",
    },
    legend: {
      data: ["理解", "疑问"],
    },
    xAxis: {
      type: "category",
      data: res.data.coordinate,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "疑问",
        data: res.data.incomprehension,
        type: "line",
      },
      {
        name: "理解",
        data: res.data.understand,
        type: "line",
      },
    ],
  };

  option && myChart.setOption(option);
};
</script>

<style lang="less" scoped>
#line {
  padding: 5px;
  box-sizing: border-box;
  height: 200px;
  background-color: rgb(237, 237, 237);
  border-radius: 10px;
}
</style>
