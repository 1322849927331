<template>
  <div id="timerBar"></div>
</template>

<script setup>
import { watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import * as echarts from "echarts/core";
import { GridComponent, TitleComponent } from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { apiStudentLearningDuration } from "@/service/teacher.js";
import { secondsToIS } from "@/common/js/utils.js";

echarts.use([GridComponent, BarChart, CanvasRenderer, TitleComponent]);

const route = useRoute();

onMounted(async () => {
  await chartsData();
});

watch(
  () => route.query.class,
  async () => {
    if (route.query.class) {
      await chartsData();
    }
  }
);

const chartsData = async () => {
  var chartDom = document.getElementById("timerBar");
  var myChart = echarts.init(chartDom);
  var option;

  let res = await apiStudentLearningDuration({
    case_id: route.query.case_id ? route.query.case_id : 1,
    gradeId: route.query.grade ? route.query.grade : 1,
    classId: route.query.class ? route.query.class : 1,
  });

  option = {
    title: {
      text: "学生做题时长",
    },
    tooltip: {
      trigger: "axis",
      valueFormatter: (value) => {
        let time = secondsToIS(value).chinese;
        return time;
      },
    },
    xAxis: {
      type: "category",
      // data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      data: res.data.students,
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: "{value} s",
      },
    },
    series: [
      {
        // data: [120, 200, 150, 80, 70, 110, 130],
        data: res.data.learningDuration,
        type: "bar",
        showBackground: true,
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.2)",
        },
      },
    ],
  };

  option && myChart.setOption(option);
};
</script>

<style lang="less" scoped>
#timerBar {
  padding: 5px;
  box-sizing: border-box;
  height: 200px;
  background-color: rgb(237, 237, 237);
  border-radius: 10px;
}
</style>
