<template>
  <div id="bar"></div>
</template>

<script setup>
import { onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { apiHistogram } from "@/service/teacher.js";

import * as echarts from "echarts/core";
import { GridComponent, TitleComponent } from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

const route = useRoute();
echarts.use([GridComponent, BarChart, CanvasRenderer, TitleComponent]);

watch(
  () => [route.query.class],
  async () => {
    if (route.query.class) {
      await chartData();
    }
  }
);

onMounted(async () => {
  await chartData();
});

const chartData = async () => {
  var chartDom = document.getElementById("bar");
  var myChart = echarts.init(chartDom);

  let res = await apiHistogram({
    case_id: route.query.case_id ? route.query.case_id : 1,
    gradeId: route.query.grade ? route.query.grade : 1,
    classId: route.query.class ? route.query.class : 1,
  });

  var option;

  option = {
    title: {
      text: "客观题正确率",
    },
    tooltip: {
      trigger: "axis",
      valueFormatter: (value) => value + " %",
    },
    xAxis: {
      type: "category",
      data: res.data.coordinate,
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: "{value} %",
      },
    },
    series: [
      {
        data: res.data.no_value,
        type: "bar",
        showBackground: true,
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.2)",
        },
      },
    ],
  };

  option && myChart.setOption(option);
};
</script>

<style lang="less" scoped>
#bar {
  padding: 5px;
  box-sizing: border-box;
  height: 200px;
  background-color: rgb(237, 237, 237);
  border-radius: 10px;
}
</style>
