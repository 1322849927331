<template>
  <div class="child">
    <div class="dataAndStudentList">
      <div
        class="data"
        :class="switchDataAndsList ? 'isList' : ''"
        @click="switchDataAndsList = true"
      >
        数据分析
      </div>
      <div
        class="studentList"
        :class="switchDataAndsList ? '' : 'isList'"
        @click="switchDataAndsList = false"
      >
        学生列表
      </div>
    </div>

    <div v-show="switchDataAndsList">
      <!-- 题目疑问数 -->
      <div class="pd">
        <UnderstandLine></UnderstandLine>
      </div>
      <!-- 主观题订正数 -->
      <div class="pd">
        <CorrectionPie></CorrectionPie>
      </div>
      <!-- 客观题正确率 -->
      <div class="pd">
        <CorrectBar></CorrectBar>
      </div>
      <div class="pd">
        <doTime></doTime>
      </div>
    </div>

    <div v-show="!switchDataAndsList">
      <van-tabs v-model:active="active" @change="changeTab">
        <van-tab :title="`全部（${studentStatistics.total}）`">
          <van-grid :gutter="10" :column-num="3">
            <van-grid-item
              v-for="item in studentInfo"
              @click="
                gotoWork(
                  item.studentsNo,
                  item.gradeID,
                  item.classID,
                  item.isFinished
                )
              "
            >
              <div class="studentInfo">
                <div class="headerImg">
                  <van-image
                    round
                    width="4vw"
                    height="4vw"
                    :src="
                      item.sex == '0'
                        ? require('@/assets/images/boy.jpg')
                        : require('@/assets/images/girl.jpg')
                    "
                  />
                </div>
                <div class="textInfo" v-if="item.statistics ? true : false">
                  <div class="nameBox">{{ item.studentName }}</div>
                  <div class="correction">
                    订正数：{{ item.statistics.prierTotal }}
                  </div>
                  <div class="false">
                    错题数：{{ item.statistics.wrongTotal }}
                  </div>
                  <div class="true">
                    正确数：{{ item.statistics.trueTotal }}
                  </div>
                </div>
                <div class="textInfo" v-if="item.statistics ? false : true">
                  <div class="nameBox">{{ item.studentName }}</div>
                  <div class="true">
                    学习时长：{{
                      secondsToIS(item.resource.learning_duration).clock
                    }}
                  </div>
                </div>
              </div>
            </van-grid-item>
          </van-grid>
        </van-tab>
        <van-tab
          :title="
            resource
              ? `未提交(${studentStatistics.unFinished})`
              : `未学习(${studentStatistics.unFinished})`
          "
        >
          <van-grid :gutter="10" :column-num="3">
            <van-grid-item
              v-for="item in studentInfo"
              @click="
                gotoWork(
                  item.studentsNo,
                  item.gradeID,
                  item.classID,
                  item.isFinished
                )
              "
            >
              <div class="studentInfo">
                <div class="headerImg">
                  <van-image
                    round
                    width="4vw"
                    height="4vw"
                    :src="
                      item.sex == '0'
                        ? require('@/assets/images/boy.jpg')
                        : require('@/assets/images/girl.jpg')
                    "
                  />
                </div>
                <div class="textInfo" v-if="item.statistics ? true : false">
                  <div class="nameBox">{{ item.studentName }}</div>
                  <div class="correction">
                    订正数：{{ item.statistics.prierTotal }}
                  </div>
                  <div class="false">
                    错题数：{{ item.statistics.wrongTotal }}
                  </div>
                  <div class="true">
                    正确数：{{ item.statistics.trueTotal }}
                  </div>
                </div>
                <div class="textInfo" v-if="item.statistics ? false : true">
                  <div class="nameBox">{{ item.studentName }}</div>
                  <div class="true">
                    学习时长：{{
                      secondsToIS(item.resource.learning_duration).clock
                    }}
                  </div>
                </div>
              </div>
            </van-grid-item>
          </van-grid>
        </van-tab>
        <van-tab
          :title="
            resource
              ? `已提交(${studentStatistics.isFinished})`
              : `已学习(${studentStatistics.isFinished})`
          "
        >
          <van-grid :gutter="10" :column-num="3">
            <van-grid-item
              v-for="item in studentInfo"
              @click="
                gotoWork(
                  item.studentsNo,
                  item.gradeID,
                  item.classID,
                  item.isFinished
                )
              "
            >
              <div class="studentInfo">
                <div class="headerImg">
                  <van-image
                    round
                    width="4vw"
                    height="4vw"
                    :src="
                      item.sex == '0'
                        ? require('@/assets/images/boy.jpg')
                        : require('@/assets/images/girl.jpg')
                    "
                  />
                </div>
                <div class="textInfo" v-if="item.statistics ? true : false">
                  <div class="nameBox">{{ item.studentName }}</div>
                  <div class="correction">
                    订正数：{{ item.statistics.prierTotal }}
                  </div>
                  <div class="false">
                    错题数：{{ item.statistics.wrongTotal }}
                  </div>
                  <div class="true">
                    正确数：{{ item.statistics.trueTotal }}
                  </div>
                </div>
                <div class="textInfo" v-if="item.statistics ? false : true">
                  <div class="nameBox">{{ item.studentName }}</div>
                  <div class="true">
                    学习时长：{{
                      secondsToIS(item.resource.learning_duration).clock
                    }}
                  </div>
                </div>
              </div>
            </van-grid-item>
          </van-grid>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import UnderstandLine from "@/views/chart/UnderstandLine.vue";
import CorrectionPie from "@/views/chart/CorrectionPie.vue";
import CorrectBar from "@/views/chart/CorrectBar.vue";
import doTime from "@/views/chart/TimerBar.vue";

import {
  apiGetStudentCount,
  apiGetStudentList,
  apiGetResStudentList,
  apiGetResStudentTotal,
} from "@/service/teacher.js";
import { secondsToIS } from "@/common/js/utils.js";
import { Toast } from "vant";

const route = useRoute();
const router = useRouter();

let resource = ref(true);

watch(
  () => [route.query.resourceID],
  async () => {
    if (+route.query.resourceID) {
      active.value = 0;
      resource.value = false;

      await getResStudentList(0);

      let total = await apiGetResStudentTotal({
        caseID: route.query.case_id,
        gradeId: route.query.grade,
        classId: route.query.class,
        resourceID: route.query.resourceID,
      });
      if (total) {
        studentStatistics.value.total = total.data.studentTotal;
        studentStatistics.value.isFinished = total.data.learnTotal;
        studentStatistics.value.unFinished = total.data.unLearnTotal;
      } else {
        studentStatistics.value = {
          isFinished: 0,
          unFinished: 0,
          total: 0,
        };
      }
    } else {
      resource.value = true;
      active.value = 0;
      if (route.query.case_id) {
        getStudentList(0);
        getStudentCountApi();
      }
    }
  }
);

let switchDataAndsList = ref(true);

let active = ref(0);
let studentStatistics = ref({});
let studentInfo = ref([]);
let unFinishedArr = ref([]);
let isFinishedArr = ref([]);

const getStudentCountApi = async () => {
  let res = await apiGetStudentCount({
    caseId: route.query.case_id,
    gradeId: route.query.grade ? route.query.grade : route.query.gradeId,
    classId: route.query.class ? route.query.class : route.query.classId,
  });
  if (res) {
    studentStatistics.value = res.data;
  } else {
    studentStatistics.value = {
      isFinished: 0,
      unFinished: 0,
      total: 0,
    };
  }
};
getStudentCountApi();

// 获取学生列表
const getStudentList = async (finished) => {
  let params = {
    gradeId: route.query.grade
      ? route.query.grade
      : studentInfo.value[0].gradeID,
    classId: route.query.class
      ? route.query.class
      : studentInfo.value[0].classID,
    caseID: route.query.case_id ? route.query.case_id : 1,
    isFinished: finished,
  };
  let res = await apiGetStudentList(params);
  studentInfo.value = res.data;
  res.data.forEach((item) => {
    if (item.isFinished) {
      isFinishedArr.value.push(item);
    } else {
      unFinishedArr.value.push(item);
    }
  });
};
getStudentList(0);

// 获取资源学生列表
const getResStudentList = async (status) => {
  let res = await apiGetResStudentList({
    caseID: route.query.case_id,
    gradeId: route.query.grade,
    classId: route.query.class,
    resourceID: +route.query.resourceID ? route.query.resourceID : "",
    status,
  });
  if (res) {
    studentInfo.value = res.data;
  }
};

const changeTab = async (e) => {
  if (+route.query.resourceID) {
    getResStudentList(e);
  } else {
    getStudentList(e);
  }
};

// 学生作业详情
const gotoWork = (No, gradeId, classId, isFinished) => {
  if (+route.query.resourceID) {
    return;
  }
  if (isFinished) {
    router.push({
      path: "/teacher/studentwork",
      query: {
        No,
        case_id: route.query.case_id,
        gradeId,
        classId,
      },
    });
  } else {
    Toast.fail("该生未完成学案");
  }
};
</script>

<style lang="less" scoped>
.dataAndStudentList {
  display: flex;
  padding: 5px;
  font-size: 12px;
  cursor: pointer;
  div {
    margin-right: 20px;
  }
}

.isList {
  color: rgb(102, 204, 255);
}

.pd {
  padding: 5px;
}

.studentInfo {
  display: flex;
  width: 100%;
  position: relative;
}

.textInfo {
  div {
    line-height: 15px;
  }
}

.nameBox {
  font-size: 13px;
  color: #884513;
  margin-bottom: 5px;
}

.correction {
  color: rgb(255, 151, 106);
}
.false {
  color: red;
}
.true {
  color: #00c297;
}

.finishd,
.await {
  position: absolute;
  height: 15px;
  line-height: 15px;
  padding: 0 5px;
  border-radius: 4px;
  right: 5px;
  top: 5px;
}

.finishd {
  border: 1px solid rgb(7, 193, 96);
  color: rgb(7, 193, 96);
}
.await {
  border: 1px solid red;
  color: red;
}

.studentEval {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 3px;
  background-color: rgb(0, 194, 151);
  color: #fff;

  border-radius: 3px;
}

.examines {
  border: 1px solid rgb(7, 193, 96);
  color: rgb(7, 193, 96);
  position: absolute;
  height: 15px;
  line-height: 15px;
  padding: 0 5px;
  border-radius: 4px;
  right: 5px;
  top: 25px;
}

.unExamines {
  border: 1px solid red;
  color: red;
  position: absolute;
  height: 15px;
  line-height: 15px;
  padding: 0 5px;
  border-radius: 4px;
  right: 5px;
  top: 25px;
}

#isShow {
  opacity: 0;
}
</style>
